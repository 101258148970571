import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook"
import BusinessIcon from "@mui/icons-material/Business"
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined"
import ExploreIcon from "@mui/icons-material/Explore"
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined"
import InfoIcon from "@mui/icons-material/InfoOutlined"
import InfoIconOutlined from "@mui/icons-material/InfoOutlined"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined"
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined"
import Alert from "@mui/material/Alert"
import Autocomplete from "@mui/material/Autocomplete"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Checkbox from "@mui/material/Checkbox"
import Chip from "@mui/material/Chip"
import Collapse from "@mui/material/Collapse"
import Container from "@mui/material/Container"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormGroup from "@mui/material/FormGroup"
import FormHelperText from "@mui/material/FormHelperText"
import InputAdornment from "@mui/material/InputAdornment"
import InputLabel from "@mui/material/InputLabel"
import LinearProgress from "@mui/material/LinearProgress"
import Link from "@mui/material/Link"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import MenuItem from "@mui/material/MenuItem"
import OutlinedInput from "@mui/material/OutlinedInput"
import Paper from "@mui/material/Paper"
import Select from "@mui/material/Select"
import Slider from "@mui/material/Slider"
import Snackbar from "@mui/material/Snackbar"
import TextField from "@mui/material/TextField"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Unstable_Grid2"
import { alpha, styled, useTheme } from "@mui/material/styles"
import axios from "axios"
import { createContext, useContext, useEffect, useRef, useState } from "react"
import React from "react"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { useWizard } from "react-use-wizard"

import AgentSigningBonusSlider from "../../../common/components/AgentSigningBonusSlider"
import AnimatedWizard, {
  AnimatedStepWrapper,
} from "../../../common/components/AnimatedWizard"
import BorderedIconTitleSubtitle from "../../../common/components/BorderedIconTitleSubtitle"
import BorderlessSearchTextField, {
  InputProps as SearchInputProps,
} from "../../../common/components/BorderlessSearchTextField"
import BrandAvatar from "../../../common/components/BrandAvatar"
import BrokeragesAroundZipcodeMap from "../../../common/components/BrokeragesAroundZipcodeMap"
import BrokeragesInAgentBoardMap from "../../../common/components/BrokeragesInAgentBoardMap"
import CenterMapContainer from "../../../common/components/CenterMapContainer"
import Column from "../../../common/components/Column"
import { AlertLayout } from "../../../common/components/InfoBubble"
import LoadingButton from "../../../common/components/LoadingButton"
import PhoneAgreementCheckbox from "../../../common/components/PhoneAgreementCheckbox"
import Row from "../../../common/components/Row"
import { SignupWizardProgress } from "../../../common/components/SignupWizardProgress"
import { SmartsetterLogoTitleLayout } from "../../../common/components/VerticalSplitLayout"
import { addImageLayerToMap, useMapLoader } from "../../../common/map-utils"
import BrokeragePurpleIcon from "../../../common/resources/icons/brokerage-purple.png"
import PinIcon from "../../../common/resources/icons/pin.png"
import {
  axiosClient,
  formatCurrency,
  getFullName,
  isEnterKeyEvent,
  optionalParseInt,
  segmentAnalytics,
  stringCapitalize,
  useIsMobile,
  useRetrieveAllBrands,
  useTrackCampaignConversion,
} from "../../../common/utils"
import { validateZipcode } from "../../../common/utils"
import LevelOfInterestAutocomplete, {
  useLevelOfInterestAutocompleteState,
} from "../../agent/components/LevelOfInterestAutocomplete"
import {
  AGENT_LEVELS_OF_INTEREST,
  mixpanelUpdateAgent,
} from "../../agent/utils"
import ConfirmLeaveSignupWizardDialog from "../components/ConfirmLeaveSignupWizardDialog"
import PasswordFields from "../components/PasswordFields"
import {
  useHandleStepInputKeyDownEventWrapper,
  useNameStepState,
} from "../components/SignupWizardSteps"
import StepContentContainer from "../components/StepContentContainer"
import VerifyPhoneCodeDialog from "../components/VerifyPhoneCodeDialog"
import { storeLoginData } from "../slice"
import {
  USER_TYPES,
  UnfinishedDataContext,
  agentCanEnterSigningBonus,
  mixpanelIdentifyUser,
  mixpanelTrackSignupWizardFinished,
  useSendPhoneVerificationSMS,
  useSetUserPassword,
  useSignupCampaign,
  useTrackBrowserClosed,
  useTrackSignupStep,
  useValidatePassowrds,
  useValidateSignupData,
} from "../utils"
import { StepPlanContext, useStepNavigator } from "../utils"
import Century21RightIcon from "./resources/century21-right.png"
import ConversationExampleImage from "./resources/conversation-example.png"
import LeftGirlIcon from "./resources/girl-left.png"
import GirlRightIcon from "./resources/girl-right.png"
import LeftGuyIcon from "./resources/guy-left.png"
import GuyRightIcon from "./resources/guy-right.png"
import MailCircleImage from "./resources/mail.png"
import RemaxLeftIcon from "./resources/remax-left.png"
import { getCountry } from "./utils"

const SELECT_BROKERAGES_MIXPANEL_STEP_TITLE =
  "There are X brokerages in your area"

const AVERAGE_HOME_PRICE = 760e3
const STEP_INDEXES = {
  name: 0,
  zipcode: 1,
  loadingBrokerages: 2,
  selectBrokeragesFromAgentBoard: 3,
  distanceFromHome: 4,
  selectBrokerages: 5,
  increaseOffers: 6,
  signingBonus: 7,
  password: 8,
  phoneCode: 9,
  allSet: 10,
  adminConfirmationLinkSent: 11,
}

export default function AgentSignup() {
  const [searchParams, setSearchParams] = useSearchParams()
  const initialFirstName = searchParams.get("f") || ""
  const initialLastName = searchParams.get("l") || ""
  const initialEmail = searchParams.get("e") || ""
  let wizardStartIndex = 0
  if (initialFirstName) {
    wizardStartIndex = 1
  }
  const { firstName, setFirstName, lastName, setLastName } = useNameStepState({
    defaultFirstName: initialFirstName,
    defaultLastName: initialLastName,
  })
  const {
    email,
    setEmail,
    emailError,
    phone,
    setPhone,
    phoneError,
    setPhoneError,
    validateSignupData,
    isValidatingData,
  } = useValidateSignupData({ defaultEmail: initialEmail })
  const [yearsInBusiness, setYearsInBusiness] = useState(0)
  const [homesSoldLastYear, setHomesSoldLastYear] = useState(0)
  const [averagePricePerHome, setAveragePricePerHome] =
    useState(AVERAGE_HOME_PRICE)
  const [signingBonus, setSigningBonus] = useState(0)
  const {
    levelOfInterest,
    setLevelOfInterest,
    levelOfInterestInputValue,
    setLevelOfInterestInputValue,
  } = useLevelOfInterestAutocompleteState()
  const [zipcode, setZipcode] = useState("")
  const [zipcodeError, setZipcodeError] = useState(null)
  const [pinLocation, setPinLocation] = useState(null)
  const [pinLocationZoomLevel, setPinLocationZoomLevel] = useState(4.3)
  const [agentBoardID, setAgentBoardID] = useState(null)
  const [brokerageCircles, setBrokerageCircles] = useState([])
  const [currentBrokerageCircle, setCurrentBrokerageCircle] = useState({})
  const [brokeragesDataLoaded, setBrokeragesDataLoaded] = useState(false)
  const [brokerages, setBrokerages] = useState([])
  const [boardMapBrokerages, setBoardMapBrokerages] = useState([])
  const [isLoadingBrokerages, setIsLoadingBrokerages] = useState(false)
  const [selectedBrokeragesStatus, setSelectedBrokeragesStatus] = useState({})
  const [currentBrokerageInputValue, setCurrentBrokerageInputValue] =
    useState("")
  const [currentBrokerage, setCurrentBrokerage] = useState(null)
  const [brokerageChecklistExpanded, setBrokerageChecklistExpanded] =
    useState(false)
  const {
    password,
    setPassword,
    password2,
    setPassword2,
    passwordError,
    setPasswordErrorFromData,
  } = useSetUserPassword()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [signupFinished, setSignupFinished] = useState(false)
  const [signupErrorSnackbarOpen, setSignupErrorSnackbarOpen] = useState(false)
  const dispatch = useDispatch()
  const trackCampaignConversion = useTrackCampaignConversion()
  const isAdminSignup = useIsAdminSignup()
  const { getSignupCampaign, clearSignupCampaign } = useSignupCampaign()

  const agentBoardCommonSteps = [
    STEP_INDEXES.name,
    STEP_INDEXES.zipcode,
    STEP_INDEXES.loadingBrokerages,
    STEP_INDEXES.selectBrokeragesFromAgentBoard,
    STEP_INDEXES.increaseOffers,
    STEP_INDEXES.signingBonus,
  ]
  const agentBoardSteps = isAdminSignup
    ? [
        ...agentBoardCommonSteps,
        STEP_INDEXES.phoneCode,
        STEP_INDEXES.adminConfirmationLinkSent,
      ]
    : [
        ...agentBoardCommonSteps,
        STEP_INDEXES.password,
        STEP_INDEXES.phoneCode,
        STEP_INDEXES.allSet,
      ]
  const distanceFromHomeCommonSteps = [
    STEP_INDEXES.name,
    STEP_INDEXES.zipcode,
    STEP_INDEXES.loadingBrokerages,
    STEP_INDEXES.distanceFromHome,
    STEP_INDEXES.selectBrokerages,
    STEP_INDEXES.increaseOffers,
    STEP_INDEXES.signingBonus,
  ]
  const distanceFromHomeSteps = isAdminSignup
    ? [
        ...distanceFromHomeCommonSteps,
        STEP_INDEXES.phoneCode,
        STEP_INDEXES.adminConfirmationLinkSent,
      ]
    : [
        ...distanceFromHomeCommonSteps,
        STEP_INDEXES.password,
        STEP_INDEXES.phoneCode,
        STEP_INDEXES.allSet,
      ]

  const stepIndexes = agentBoardID ? agentBoardSteps : distanceFromHomeSteps

  const selectedBrokerages = brokerages.filter(
    (b, index) => selectedBrokeragesStatus[b.id]
  )
  const salesVolumeLastYear = (homesSoldLastYear || 0) * averagePricePerHome
  const canEnterSigningBonus = agentCanEnterSigningBonus(
    yearsInBusiness,
    salesVolumeLastYear
  )

  const unfinishedSignupData = { firstName, lastName, email }
  useTrackBrowserClosed(unfinishedSignupData)

  function createSelectedBrokeragesStatus(brokerages) {
    return Object.fromEntries(brokerages.map((b) => [b.id, true]))
  }

  function updateBrokerages(brokerages) {
    setBrokerages(brokerages)
    setSelectedBrokeragesStatus(createSelectedBrokeragesStatus(brokerages))
  }

  function handleLoadingBrokeragesStepReached() {
    if (agentBoardID) {
      axiosClient
        .get(`/agents/api/brokerages-in-agent-board/?id=${agentBoardID}`)
        .then(({ data }) => {
          updateBrokerages(data.board_brokerages)
          setBoardMapBrokerages(data.map_brokerages)
          setBrokeragesDataLoaded(true)
        })
    } else {
      axiosClient
        .get("/agents/api/brokerage-circles-finder/", {
          params: { lat: pinLocation.lat, lng: pinLocation.lng },
        })
        .then(({ data }) => {
          setBrokeragesDataLoaded(true)
          const brokerageCircles = data.circles.map((c) => ({
            distance: c[0],
            brokerageCount: c[1],
          }))
          // data is a map between distances to brokerage counts
          setBrokerageCircles(brokerageCircles)
          if (brokerageCircles.length > 0) {
            setCurrentBrokerageCircle(brokerageCircles[0])
            updateBrokerages(data.brokerages_first_circle)
          } else {
            setCurrentBrokerageCircle({})
            updateBrokerages([])
          }
        })
    }
  }

  function handleBrokerageCircleChanged(c) {
    setCurrentBrokerageCircle(c)
    setIsLoadingBrokerages(true)
    axiosClient
      .get("/agents/api/brokerages-in-circle/", {
        params: {
          lng: pinLocation.lng,
          lat: pinLocation.lat,
          distance: c.distance,
        },
      })
      .then(({ data }) => {
        updateBrokerages(data)
      })
      .finally(() => setIsLoadingBrokerages(false))
  }

  function handleBrokerageSelectedChanged(id, checked) {
    setSelectedBrokeragesStatus({ ...selectedBrokeragesStatus, [id]: checked })
  }

  function handleCurrentBrokerageChanged(newCurrentBrokerage) {
    setCurrentBrokerage(newCurrentBrokerage)
    // unselect agent new brokerage and enable all the others
    let newSelectStatus
    if (newCurrentBrokerage) {
      newSelectStatus = {
        ...createSelectedBrokeragesStatus(brokerages),
        [newCurrentBrokerage.id]: false,
      }
    } else {
      newSelectStatus = createSelectedBrokeragesStatus(brokerages)
    }
    setSelectedBrokeragesStatus(newSelectStatus)
  }

  function handleBrandSelectedChanged(brandID, checked) {
    const brokeragesWithBrand = brokerages.filter(
      (b) => b.brand?.id === brandID
    )
    const brokeragesWithBrandAsObj = Object.fromEntries(
      brokeragesWithBrand.map((b) => [b.id, checked])
    )
    setSelectedBrokeragesStatus({
      ...selectedBrokeragesStatus,
      ...brokeragesWithBrandAsObj,
    })
  }

  function handleDistanceStepBackButtonClicked() {
    setBrokeragesDataLoaded(false)
    setBrokerageCircles([])
    updateBrokerages([])
  }

  function handlePhoneVerificationSent() {
    if (isAdminSignup) {
      // means phone submitted
      const formData = getCommonSignupFormData()
      formData.append("phone", phone)
      submitSignup("/agents/api/admin-signup/", formData).catch(
        ({ response: { data } }) => {
          if (data.phone) {
            setPhoneError(data.phone[0])
          }
        }
      )
    } else {
      handleActivationCodeSentStepReached()
    }
  }

  function handlePasswordValidated() {
    const formData = getCommonSignupFormData()

    formData.append("password", password)

    const signupCampaign = getSignupCampaign()
    if (signupCampaign) {
      formData.append("signup_campaign", signupCampaign)
    }

    submitSignup("/agents/api/signup/", formData).then(({ data }) => {
      dispatch(storeLoginData(data))
      mixpanelTrackSignupWizardFinished(USER_TYPES.agent)
      mixpanelIdentifyUser(data)
      trackCampaignConversion(email)
      clearSignupCampaign()
    })
  }

  function handleActivationCodeSentStepReached() {
    trackBigWebhookData("Activate your account")
  }

  function handleLastStepReached() {
    trackBigWebhookData("You are all set!")
  }

  function getCommonSignupFormData() {
    const formData = new FormData()
    formData.append("first_name", firstName)
    formData.append("last_name", lastName)
    formData.append("email", email)
    formData.append("years_in_business", yearsInBusiness)
    formData.append("sales_volume_last_year", salesVolumeLastYear)
    formData.append("homes_sold_last_year", homesSoldLastYear)
    formData.append("level_of_interest", levelOfInterest)
    formData.append("signing_bonus", signingBonus)
    formData.append("zip_code", zipcode)
    formData.append("location", `${pinLocation.lng},${pinLocation.lat}`)
    if (currentBrokerageCircle.distance) {
      formData.append(
        "distance_from_work",
        parseInt(currentBrokerageCircle.distance)
      )
    }
    if (currentBrokerage) {
      formData.append("current_brokerage", currentBrokerage.id)
    }
    formData.append(
      "brokerages_of_interest",
      selectedBrokerages.map(({ id }) => id).join(",")
    )
    if (agentBoardID) {
      formData.append("board", agentBoardID)
    }
    return formData
  }

  function submitSignup(apiURL, formData) {
    setIsSubmitting(true)
    return axiosClient
      .post(apiURL, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(({ data }) => {
        setSignupFinished(true)
        return Promise.resolve({ data })
      })
      .catch(({ response: { data } }) => {
        setSignupErrorSnackbarOpen(true)
        return Promise.reject(data)
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  function trackBigWebhookData(stepTitle) {
    webhookTrack({
      stepTitle,
      firstName,
      lastName,
      email,
      phone,
      zipcode,
      yearsInBusiness,
      homesSoldLastYear,
      salesVolumeLastYear,
      levelOfInterest: AGENT_LEVELS_OF_INTEREST[levelOfInterest],
      selectedBrokerageCount: selectedBrokerages.length,
    })
  }

  useEffect(
    () => {
      if (initialFirstName) {
        setFirstName(initialFirstName)
        setLastName(initialLastName)
        setEmail({ target: { value: initialEmail } })
        const zipcode = searchParams.get("z")
        const newSearchParams = new URLSearchParams()
        if (zipcode) {
          newSearchParams.set("z", zipcode)
        }
        setSearchParams(newSearchParams)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams]
  )

  return (
    <Container sx={{ py: { xs: 4, sm: 8 } }}>
      <Grid container columnSpacing={8}>
        <Grid xs={12} sm={3}>
          <SmartsetterLogoTitleLayout />
        </Grid>
        <StepPlanContext.Provider value={stepIndexes}>
          <UnfinishedDataContext.Provider value={unfinishedSignupData}>
            <IsSigningUpContext.Provider value={isSubmitting}>
              <AnimatedWizard wizardProps={{ startIndex: wizardStartIndex }}>
                <NameStep
                  firstName={firstName}
                  setFirstName={setFirstName}
                  lastName={lastName}
                  setLastName={setLastName}
                  email={email}
                  setEmail={setEmail}
                  emailError={emailError}
                  validateSignupData={validateSignupData}
                  isValidatingData={isValidatingData}
                />
                <ZipcodeStep
                  zipcode={zipcode}
                  setZipcode={setZipcode}
                  zipcodeError={zipcodeError}
                  setZipcodeError={setZipcodeError}
                  pinLocation={pinLocation}
                  setAgentBoardID={setAgentBoardID}
                  onLocationChanged={setPinLocation}
                  zoomLevel={pinLocationZoomLevel}
                  onZoomChanged={setPinLocationZoomLevel}
                />
                <LoadingBrokeragesStep
                  onStepReached={handleLoadingBrokeragesStepReached}
                  goToNextStep={brokeragesDataLoaded}
                />
                <SelectBrokeragesFromAgentBoardStep
                  brokerages={brokerages}
                  boardMapBrokerages={boardMapBrokerages}
                  isLoadingBrokerages={isLoadingBrokerages}
                  selectedBrokerages={selectedBrokerages}
                  selectedBrokeragesStatus={selectedBrokeragesStatus}
                  currentBrokerageInputValue={currentBrokerageInputValue}
                  setCurrentBrokerageInputValue={setCurrentBrokerageInputValue}
                  currentBrokerage={currentBrokerage}
                  setCurrentBrokerage={handleCurrentBrokerageChanged}
                  brokerageChecklistExpanded={brokerageChecklistExpanded}
                  setBrokerageChecklistExpanded={setBrokerageChecklistExpanded}
                  onBrokerageSelectedChanged={handleBrokerageSelectedChanged}
                  pinLocation={pinLocation}
                />
                <DistanceFromHomeStep
                  brokerageCircles={brokerageCircles}
                  currentBrokerageCircle={currentBrokerageCircle}
                  setCurrentBrokerageCircle={handleBrokerageCircleChanged}
                  pinLocation={pinLocation}
                  brokerages={brokerages}
                  isLoadingBrokerages={isLoadingBrokerages}
                  onBackButtonClicked={handleDistanceStepBackButtonClicked}
                />
                <SelectBrokeragesStep
                  brokerageCircle={currentBrokerageCircle}
                  brokerages={brokerages}
                  pinLocation={pinLocation}
                  selectedBrokeragesStatus={selectedBrokeragesStatus}
                  selectedBrokerages={selectedBrokerages}
                  onBrokerageSelectedChanged={handleBrokerageSelectedChanged}
                  onBrandSelectedChanged={handleBrandSelectedChanged}
                />
                <IncreaseOffersStep
                  yearsInBusiness={yearsInBusiness}
                  setYearsInBusiness={setYearsInBusiness}
                  homesSoldLastYear={homesSoldLastYear}
                  setHomesSoldLastYear={setHomesSoldLastYear}
                  averagePricePerHome={averagePricePerHome}
                  salesVolumeLastYear={salesVolumeLastYear}
                  setAveragePricePerHome={setAveragePricePerHome}
                  canEnterSigningBonus={canEnterSigningBonus}
                  levelOfInterest={levelOfInterest}
                  setLevelOfInterest={setLevelOfInterest}
                  levelOfInterestInputValue={levelOfInterestInputValue}
                  setLevelOfInterestInputValue={setLevelOfInterestInputValue}
                />
                <SigningBonusStep
                  signingBonus={signingBonus}
                  setSigningBonus={setSigningBonus}
                />
                <SetUpPasswordStep
                  password={password}
                  setPassword={setPassword}
                  password2={password2}
                  setPassword2={setPassword2}
                  passwordError={passwordError}
                  setPasswordErrorFromData={setPasswordErrorFromData}
                  onPasswordValidated={handlePasswordValidated}
                  canEnterSigningBonus={canEnterSigningBonus}
                  isSigningUp={isSubmitting}
                  signupFinished={signupFinished}
                />
                <SendPhoneCodeStep
                  phone={phone}
                  setPhone={setPhone}
                  phoneError={phoneError}
                  setPhoneError={setPhoneError}
                  onPhoneCodeSent={handlePhoneVerificationSent}
                />
                <AllSetStep onLastStepReached={handleLastStepReached} />
                <AdminPhoneConfirmationLinkSentStep />
              </AnimatedWizard>
            </IsSigningUpContext.Provider>
          </UnfinishedDataContext.Provider>
        </StepPlanContext.Provider>
      </Grid>
      <SignupErrorSnackbar
        open={signupErrorSnackbarOpen}
        onClose={() => setSignupErrorSnackbarOpen(false)}
      />
    </Container>
  )
}

function NameStep({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  emailError,
  subtitle = `See local brokerage offers.<br/>100% free. No credit card needed.`,
  validateSignupData,
  isValidatingData,
}) {
  const { nextStep } = useStepNavigator()
  const gtmTrack = useGTMDispatch()
  const mixpanelTrackStepFinished = useMixpanelTrackStepFinished()
  const klaviyoTrackAnonymousStep =
    useKlaviyoTrackAnonymousStep("personal_info")
  const isNextEnabled = firstName && lastName && email
  const stepTitle = "Tell us about yourself"
  const mixpanelStepFinishedData = {
    $first_name: firstName,
    $last_name: lastName,
    $name: getFullName({ first_name: firstName, last_name: lastName }),
    $email: email,
  }

  function handleKeyDownEvent(e) {
    if (isNextEnabled && isEnterKeyEvent(e)) {
      handleNextButtonClicked()
    }
  }

  function handleNextButtonClicked() {
    validateSignupData().then(() => {
      // handleStep is not working here
      // It's not going to work because I'm using goToStep
      gtmTrack({ event: "name_email_submitted", firstName, lastName, email })
      webhookTrack({ stepTitle, firstName, lastName, email })
      segmentAnalytics.identify(email)
      mixpanelTrackStepFinished(mixpanelStepFinishedData, stepTitle)
      klaviyoTrackAnonymousStep({
        first_name: firstName,
        last_name: lastName,
        email,
      })
      nextStep()
    })
  }

  return (
    <StepLayout
      isNextEnabled={isNextEnabled}
      nextButtonLabel="Continue"
      title={stepTitle}
      subtitle={subtitle}
      onNextButtonClicked={handleNextButtonClicked}
      isLoading={isValidatingData}
      mixpanelStepFinishedData={mixpanelStepFinishedData}
    >
      <TextField
        label="First Name"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        onKeyDown={handleKeyDownEvent}
        fullWidth
        autoFocus
      />
      <TextField
        label="Last Name"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        onKeyDown={handleKeyDownEvent}
        fullWidth
        sx={{ mt: 3 }}
      />
      <TextField
        label="Email"
        type="email"
        value={email}
        onChange={setEmail}
        onKeyDown={handleKeyDownEvent}
        error={!!emailError}
        helperText={emailError}
        fullWidth
        sx={{ mt: 3 }}
      />
    </StepLayout>
  )
}

function ZipcodeStep({
  zipcode,
  setZipcode,
  zipcodeError,
  setZipcodeError,
  pinLocation,
  onLocationChanged,
  setAgentBoardID,
  zoomLevel,
  onZoomChanged,
}) {
  const mapContainer = useRef()
  const { map, mapLoaded } = useMapLoader(mapContainer)
  const [isLoading, setIsLoading] = useState(false)
  const { nextStep } = useStepNavigator()
  const isNextEnabled =
    validateZipcode(zipcode) && !zipcodeError && !!pinLocation
  const [searchParams, setSearchParams] = useSearchParams()
  const initialZipcode = searchParams.get("z")
  const mixpanelStepFinishedData = {
    zip_code: zipcode,
  }
  const zipOrPostal = getCountry() === "Canada" ? "postal" : "zip"
  const capitalizedZipOrPostal = stringCapitalize(zipOrPostal)
  const mixpanelStepTitle = "What's your zip code?"
  const mixpanelTrackStepFinished = useMixpanelTrackStepFinished()
  const klaviyoTrackAnonymousStep = useKlaviyoTrackAnonymousStep("zip_code")
  const handleKeyDownEvent = useHandleStepInputKeyDownEventWrapper(
    isNextEnabled,
    handleNextButtonClicked
  )

  function getGeojsonSourceDataFromMapPoint(point) {
    return {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [point.lng, point.lat],
          },
        },
      ],
    }
  }

  function handleZipcodeChanged(e) {
    const newZipcode = e.target.value
    setZipcode(newZipcode)
    setZipcodeError(null)
    if (validateZipcode(newZipcode)) {
      queryZipcode(newZipcode)
    }
  }

  function handleShowZipcodeOnMapButtonClicked() {
    queryZipcode(zipcode)
  }

  function queryZipcode(zipcode) {
    setIsLoading(true)
    return axiosClient
      .get("/agents/api/validate-zip-code-filter/", {
        params: { zip_code: zipcode },
      })
      .then(({ data }) => {
        const coordinates = data.location.coordinates
        onLocationChanged({ lng: coordinates[0], lat: coordinates[1] })
        setAgentBoardID(data.agent_board_id)
        setZipcodeError(null)
        map.current.flyTo({ center: coordinates, essential: true })
        if (initialZipcode) {
          setSearchParams(new URLSearchParams())
          nextStep()
        }
      })
      .catch(() => {
        setZipcodeError(
          `${capitalizedZipOrPostal} code not found. Try using a different ${zipOrPostal} code or use the map to pin your exact location.`
        )
      })
      .finally(() => setIsLoading(false))
  }

  function handleNextButtonClicked() {
    mixpanelTrackStepFinished(mixpanelStepFinishedData, mixpanelStepTitle)
    klaviyoTrackAnonymousStep({ zip_code: zipcode })
    nextStep()
  }

  useEffect(
    () => {
      if (initialZipcode) {
        handleZipcodeChanged({ target: { value: initialZipcode } })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchParams]
  )

  useEffect(
    () => {
      if (!mapLoaded) return
      const mapCenter = map.current.getCenter()
      const mapCenterData = getGeojsonSourceDataFromMapPoint(
        pinLocation || mapCenter
      )

      const PIN_LOCATION_SOURCE_ID = "location-pin"
      const PIN_IMAGE_LAYER_ID = "pin-icon"

      map.current.on("move", () => {
        const currentPosition = map.current.getCenter()
        onLocationChanged(currentPosition)
        const locationSource = map.current.getSource(PIN_LOCATION_SOURCE_ID)
        locationSource.setData(
          getGeojsonSourceDataFromMapPoint(currentPosition)
        )
      })

      map.current.on("zoomend", () => {
        onZoomChanged(map.current.getZoom())
      })

      map.current.addSource(PIN_LOCATION_SOURCE_ID, {
        type: "geojson",
        data: mapCenterData,
      })

      if (pinLocation) {
        map.current.flyTo({
          center: pinLocation,
          essential: true,
          zoom: zoomLevel,
        })
      }

      addImageLayerToMap(
        map,
        PinIcon,
        PIN_IMAGE_LAYER_ID,
        PIN_LOCATION_SOURCE_ID,
        PIN_IMAGE_LAYER_ID,
        0.25
      )

      const mapRef = map.current

      return () => {
        mapRef.removeLayer(PIN_IMAGE_LAYER_ID)
        mapRef.removeImage(PIN_IMAGE_LAYER_ID)
        mapRef.removeSource(PIN_LOCATION_SOURCE_ID)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mapLoaded]
  )

  return (
    <StepLayout
      isNextEnabled={isNextEnabled}
      onNextButtonClicked={handleNextButtonClicked}
      title={`What's your ${zipOrPostal} code?`}
      subtitle="Discover local brokerages, instantly"
      fullWidth
      rightSection={<HomeLocationMapContainer mapContainer={mapContainer} />}
      mixpanelStepTitle={mixpanelStepTitle}
      mixpanelStepFinishedData={mixpanelStepFinishedData}
    >
      <FormControl variant="outlined" fullWidth error={!!zipcodeError}>
        <InputLabel htmlFor="zipcode">{capitalizedZipOrPostal} Code</InputLabel>
        <OutlinedInput
          id="zipcode"
          endAdornment={
            <InputAdornment position="end">
              <LoadingButton
                variant="text"
                color="primary"
                disabled={!zipcode}
                isLoading={isLoading}
                onClick={handleShowZipcodeOnMapButtonClicked}
              >
                Show on map
              </LoadingButton>
            </InputAdornment>
          }
          value={zipcode}
          onChange={handleZipcodeChanged}
          onKeyDown={handleKeyDownEvent}
          autoFocus
        />
        {zipcodeError && <FormHelperText>{zipcodeError}</FormHelperText>}
      </FormControl>
    </StepLayout>
  )
}

function LoadingBrokeragesStep({ onStepReached, goToNextStep }) {
  const { nextStep } = useStepNavigator()

  useEffect(
    () => {
      onStepReached()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(
    () => {
      if (goToNextStep) {
        nextStep()
      }
    },
    // eslint-disable-next-line
    [goToNextStep]
  )

  return (
    <StepLayout
      title="Finding brokerages in your area"
      subtitle="Please wait"
      showButtons={false}
    >
      <Column
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Row
          sx={{
            justifyContent: "center",
            alignItems: "center",
            p: 2,
            borderRadius: "125px",
            width: "128px",
            height: "128px",
            background: (theme) => alpha(theme.palette.primary.light, 0.5),
          }}
        >
          <BusinessIcon color="primary" sx={{ fontSize: "4em" }} />
        </Row>
        <LinearProgress
          variant="indeterminate"
          color="primary"
          sx={{ mt: 4, width: "50%" }}
        />
      </Column>
    </StepLayout>
  )
}

function DistanceFromHomeStep({
  brokerageCircles,
  currentBrokerageCircle,
  setCurrentBrokerageCircle,
  pinLocation,
  brokerages,
  isLoadingBrokerages,
  onBackButtonClicked,
}) {
  const { goBackwards } = useStepNavigator()
  const foundBrokerages = Object.keys(currentBrokerageCircle).length > 0

  function handlePreviousButtonClicked() {
    goBackwards(2)
    onBackButtonClicked()
  }

  return (
    <StepLayout
      title={`There are ${
        foundBrokerages ? currentBrokerageCircle.brokerageCount : "no"
      } brokerages in your area`}
      mixpanelStepTitle={SELECT_BROKERAGES_MIXPANEL_STEP_TITLE}
      subtitle={
        foundBrokerages
          ? "Please select a preferred distance from your zipcode"
          : "Try going back and selecting a different location on the map"
      }
      isNextEnabled={foundBrokerages}
      onPreviousButtonClicked={handlePreviousButtonClicked}
      fullWidth
      rightSection={
        <CenterMapContainer
          Map={BrokeragesAroundZipcodeMap}
          mapProps={{
            brokerages: brokerages,
            isBrokeragesLoading: isLoadingBrokerages,
            distanceFromHome: foundBrokerages
              ? currentBrokerageCircle.distance
              : 10,

            mapCenter: createGeoJsonFromPinLocation(pinLocation),
            zoomOut: true,
          }}
          sx={{
            height: { xs: "320px", md: "100%" },
            borderRadius: (theme) => theme.units.borderRadius,
          }}
        />
      }
      mixpanelStepFinishedData={{
        distance_from_work: currentBrokerageCircle.distance,
      }}
    >
      <Column
        sx={{
          "& .MuiPaper-root:nth-of-type(n + 2)": { mt: 2 },
        }}
      >
        {brokerageCircles.map((brokerageCircle, i) => (
          <BorderedIconTitleSubtitle
            key={i}
            title={`${brokerageCircle.distance} miles`}
            subtitle={`${brokerageCircle.brokerageCount} brokerages`}
            isActive={brokerageCircle === currentBrokerageCircle}
            onClick={() => setCurrentBrokerageCircle(brokerageCircle)}
            Icon={() => (
              <img
                src={BrokeragePurpleIcon}
                style={{ width: "24px", height: "24px" }}
                alt="brokerage building"
              />
            )}
          />
        ))}
      </Column>
    </StepLayout>
  )
}

function SelectBrokeragesStep({
  brokerageCircle,
  brokerages,
  pinLocation,
  selectedBrokeragesStatus,
  selectedBrokerages,
  onBrokerageSelectedChanged,
  onBrandSelectedChanged,
}) {
  const {
    brokerageSearch,
    setBrokerageSearch,
    filteredBrokerages,
    handleBrokerageCheckedChangedWrapper,
  } = useSelectBrokerageChecklistRequirements(
    brokerages,
    onBrokerageSelectedChanged
  )
  const { allBrands } = useRetrieveAllBrands()
  const [excludedBrands, setExcludedBrands] = useState([])

  function handleExcludedBrandsChanged(e) {
    let brands = e.target.value
    if (typeof brands === "string") {
      brands = brands.split(",")
    } else {
      // remove duplicate brands (reselecting brands does this)
      brands = brands.reduce(
        (acc, brand) =>
          acc.map((b) => b.id).indexOf(brand.id) === -1
            ? acc.concat([brand])
            : acc.filter((b) => b.id !== brand.id),
        []
      )
      let moreBrands, lessBrands, checked
      if (brands.length < excludedBrands.length) {
        // brand was removed from exclusion list
        moreBrands = excludedBrands
        lessBrands = brands
        checked = true
      } else {
        moreBrands = brands
        lessBrands = excludedBrands
        checked = false
      }
      const lessBrandsIDS = lessBrands.map(({ id }) => id)
      const updatedBrand = moreBrands.filter(
        ({ id }) => lessBrandsIDS.indexOf(id) === -1
      )[0]
      onBrandSelectedChanged(updatedBrand.id, checked)
    }
    setExcludedBrands(brands)
  }

  return (
    <StepLayout
      title="These are the brokerages in your area!"
      subtitle="Uncheck any brokerages you're not interested in below"
      rightSection={
        <CenterMapContainer
          Map={BrokeragesAroundZipcodeMap}
          mapProps={{
            brokerages: selectedBrokerages,
            mapCenter: createGeoJsonFromPinLocation(pinLocation),
            distanceFromHome: brokerageCircle.distance,
            zoomOut: true,
          }}
          sx={{
            height: { xs: "320px", md: "100%" },
            borderRadius: (theme) => theme.units.borderRadius,
          }}
        />
      }
      fullWidth
      isNextEnabled={Object.entries(selectedBrokeragesStatus).some(
        ([_, selected]) => selected
      )}
      mixpanelStepFinishedData={{
        selected_brokerage_count: selectedBrokerages.length,
        total_brokerage_count: brokerages.length,
      }}
    >
      <Column>
        <AlertLayout
          Icon={() => (
            <InfoIcon sx={{ color: (theme) => theme.palette.warning.main }} />
          )}
          message="We recommend unchecking your current brokerage in order to preserve your privacy."
          wrapperSX={{
            backgroundColor: "#f8f4ef",
          }}
        />
        <FormControl sx={{ mt: 4 }}>
          <InputLabel>Exclude these brands</InputLabel>
          <Select
            multiple
            value={excludedBrands}
            onChange={handleExcludedBrandsChanged}
            input={<OutlinedInput label="Exclude these brands" />}
            renderValue={(selected) => selected.map((b) => b.name).join(", ")}
            MenuProps={{ style: { maxHeight: 250 } }}
          >
            {allBrands.map((brand) => (
              <MenuItem key={brand.id} value={brand}>
                <Checkbox
                  checked={
                    excludedBrands.map((b) => b.id).indexOf(brand.id) > -1
                  }
                />
                <ListItemIcon>
                  <img
                    src={brand.icon}
                    alt={brand.name}
                    style={{
                      width: "24px",
                      height: "24px",
                      borderRadius: "50%",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary={brand.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <BrokerageSearchTextField
          search={brokerageSearch}
          setSearch={setBrokerageSearch}
        />
        <SelectBrokeragesChecklist
          filteredBrokerages={filteredBrokerages}
          selectedBrokeragesStatus={selectedBrokeragesStatus}
          onBrokerageSelectedChangedWrapper={
            handleBrokerageCheckedChangedWrapper
          }
        />
      </Column>
    </StepLayout>
  )
}

function SelectBrokeragesFromAgentBoardStep({
  brokerages,
  boardMapBrokerages,
  isLoadingBrokerages,
  currentBrokerageInputValue,
  setCurrentBrokerageInputValue,
  currentBrokerage,
  setCurrentBrokerage,
  brokerageChecklistExpanded,
  setBrokerageChecklistExpanded,
  selectedBrokeragesStatus,
  selectedBrokerages,
  onBrokerageSelectedChanged,
  pinLocation,
}) {
  const currentBrokerageMatches = filterBrokeragesByQuery(
    brokerages,
    currentBrokerageInputValue
  )
    .slice(0, 20)
    .sort((b1, b2) => b1.company.localeCompare(b2.company))

  const {
    brokerageSearch,
    setBrokerageSearch,
    filteredBrokerages,
    handleBrokerageCheckedChangedWrapper,
  } = useSelectBrokerageChecklistRequirements(
    brokerages,
    onBrokerageSelectedChanged
  )
  const { goBackwards } = useStepNavigator()

  return (
    <StepLayout
      title="In order to protect your privacy, we will hide your profile from your current brokerage."
      superTitle={
        <>
          There are <PurpleChip label={brokerages.length} /> brokerages in your
          area.
        </>
      }
      subtitle="Please indicate your current brokerage:"
      rightSection={
        <CenterMapContainer
          Map={BrokeragesInAgentBoardMap}
          mapProps={{
            brokerages: boardMapBrokerages,
            isLoadingBrokerages,
            mapCenter: createGeoJsonFromPinLocation(pinLocation),
          }}
          sx={{
            height: { xs: "320px", sm: "640px" },
            borderRadius: (theme) => theme.units.borderRadius,
          }}
        />
      }
      fullWidth
      isNextEnabled={selectedBrokerages.length > 0}
      mixpanelStepTitle={SELECT_BROKERAGES_MIXPANEL_STEP_TITLE}
      mixpanelStepFinishedData={{
        selected_brokerage_count: selectedBrokerages.length,
        total_brokerage_count: brokerages.length,
      }}
      onPreviousButtonClicked={() => goBackwards(2)}
    >
      <Column>
        <Autocomplete
          value={currentBrokerage}
          onChange={(_, value) => setCurrentBrokerage(value)}
          inputValue={currentBrokerageInputValue}
          onInputChange={(_, newValue) =>
            setCurrentBrokerageInputValue(newValue)
          }
          renderInput={(props) => (
            <TextField
              {...props}
              label="Your current brokerage"
              placeholder="Please select your current brokerage"
            />
          )}
          options={currentBrokerageMatches}
          getOptionLabel={(option) => option.company}
          renderOption={(props, option) => (
            <Row sx={{ alignItems: "center", p: 2 }} {...props}>
              <BrandAvatar brand={{ icon: option.image }} />
              <Column sx={{ ml: 2 }}>
                <Typography variant="caption">{option.company}</Typography>
                <Row sx={{ alignItems: "center" }}>
                  <LocationOnOutlinedIcon
                    sx={{ color: "text.secondary2" }}
                    fontSize="small"
                  />
                  <Typography
                    variant="body1"
                    color="text.secondary2"
                    sx={{ ml: 0.5 }}
                  >
                    {option.address}
                  </Typography>
                </Row>
              </Column>
            </Row>
          )}
        />
        <Link
          color="primary"
          underline="none"
          href="#"
          onClick={() =>
            setBrokerageChecklistExpanded(!brokerageChecklistExpanded)
          }
          sx={{ mt: 2, display: "flex" }}
        >
          <Box sx={{ mr: 0.5 }}>
            {brokerageChecklistExpanded ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </Box>{" "}
          Click here to hide your profile from other brokerages
        </Link>
        <Collapse in={brokerageChecklistExpanded}>
          <Paper
            elevation={0}
            sx={{ display: "flex", flexDirection: "column", p: 2, mt: 2 }}
          >
            <BrokerageSearchTextField
              search={brokerageSearch}
              setSearch={setBrokerageSearch}
              mt={0}
            />
            <Typography variant="body1" fontWeight={600} sx={{ mt: 1 }}>
              Uncheck any brokerages you're not interested in:
            </Typography>
            <SelectBrokeragesChecklist
              filteredBrokerages={filteredBrokerages.slice(0, 100)}
              selectedBrokeragesStatus={selectedBrokeragesStatus}
              onBrokerageSelectedChangedWrapper={
                handleBrokerageCheckedChangedWrapper
              }
              maxHeight="200px"
            />
          </Paper>
        </Collapse>
      </Column>
    </StepLayout>
  )
}

function IncreaseOffersStep({
  yearsInBusiness,
  setYearsInBusiness,
  homesSoldLastYear,
  setHomesSoldLastYear,
  averagePricePerHome,
  setAveragePricePerHome,
  salesVolumeLastYear,
  canEnterSigningBonus,
  levelOfInterest,
  setLevelOfInterest,
  levelOfInterestInputValue,
  setLevelOfInterestInputValue,
}) {
  const { activeStep } = useWizard()
  const { goToStep } = useStepNavigator()
  const enteredAnyStepData =
    yearsInBusiness ||
    homesSoldLastYear ||
    averagePricePerHome ||
    levelOfInterest

  const isNextEnabled = true
  const averagePricePerHomeRange = { start: 1e5, end: 1e7 }
  const mixpanelStepFinishedData = {
    years_in_business: yearsInBusiness,
    homes_sold_last_year: homesSoldLastYear,
    sales_volume_last_year: salesVolumeLastYear,
    level_of_interest: AGENT_LEVELS_OF_INTEREST[levelOfInterest],
  }
  const stepTitle = "Complete your profile"
  const mixpanelTrackStepFinished = useMixpanelTrackStepFinished()

  const handleKeyDownEvent = useHandleStepInputKeyDownEventWrapper(
    isNextEnabled,
    handleNextButtonClicked
  )

  function handleNextButtonClicked() {
    mixpanelTrackStepFinished(mixpanelStepFinishedData, stepTitle)
    goToStep(canEnterSigningBonus ? activeStep + 1 : activeStep + 2)
  }

  return (
    <StepLayout
      title={stepTitle}
      subtitle="Submit your information and get instant, accurate brokerage offers."
      isNextEnabled={isNextEnabled}
      nextButtonLabel={enteredAnyStepData ? "Next" : "Skip"}
      onNextButtonClicked={handleNextButtonClicked}
      contentWidth="500px"
      mixpanelStepFinishedData={mixpanelStepFinishedData}
    >
      <Column>
        <Box>
          <Grid container spacing={2} padding={0}>
            <Grid xs={12}>
              <TextField
                label="Years in business"
                value={yearsInBusiness}
                onChange={(e) =>
                  setYearsInBusiness(optionalParseInt(e.target.value))
                }
                onKeyDown={handleKeyDownEvent}
                type="number"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarTodayOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid xs={12}>
              <TextField
                label="Homes sold last year"
                value={homesSoldLastYear}
                onChange={(e) =>
                  setHomesSoldLastYear(optionalParseInt(e.target.value))
                }
                onKeyDown={handleKeyDownEvent}
                type="number"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <HomeOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid xs={12}>
              <Column>
                <Row sx={{ mt: 2, px: 1 }}>
                  <Typography
                    variant="caption"
                    fontWeight={600}
                    minWidth="8em"
                    paddingTop="4px"
                  >
                    Avg. home price
                  </Typography>
                  <Slider
                    value={averagePricePerHome}
                    onChange={(_, newValue) => setAveragePricePerHome(newValue)}
                    min={averagePricePerHomeRange.start}
                    max={averagePricePerHomeRange.end}
                    valueLabelDisplay="auto"
                    valueLabelFormat={formatCurrency}
                    marks={[
                      { value: averagePricePerHomeRange.start, label: "100k" },
                      {
                        value: AVERAGE_HOME_PRICE,
                        label: (
                          <Tooltip
                            title={`${formatCurrency(
                              AVERAGE_HOME_PRICE
                            )} average home price in USA`}
                          >
                            <InfoIconOutlined sx={{ fontSize: "0.9em" }} />
                          </Tooltip>
                        ),
                      },
                      { value: averagePricePerHomeRange.end, label: "10M" },
                    ]}
                    step={1e4}
                    sx={{ ml: 2 }}
                  />
                </Row>
                <Collapse in={!!salesVolumeLastYear}>
                  <Typography
                    variant="h6"
                    sx={{ mt: 1, textAlign: "center", alignSelf: "center" }}
                  >
                    Total sales volume last year{" "}
                    <PurpleChip label={formatCurrency(salesVolumeLastYear)} />
                  </Typography>
                </Collapse>
              </Column>
            </Grid>
            <Grid xs={12}>
              <LevelOfInterestAutocomplete
                levelOfInterest={levelOfInterest}
                setLevelOfInterest={setLevelOfInterest}
                levelOfInterestInputValue={levelOfInterestInputValue}
                setLevelOfInterestInputValue={setLevelOfInterestInputValue}
              />
            </Grid>
          </Grid>
        </Box>
      </Column>
    </StepLayout>
  )
}

function SigningBonusStep({
  signingBonus,
  setSigningBonus,
  onAdminSignupFinished,
}) {
  return (
    <StepLayout
      title="Seeking a cash signing bonus?"
      subtitle={`Are you looking for a cash bonus to switch brokerages? Your requested bonus will be visible to brokerages.`}
      nextButtonLabel={signingBonus ? "Continue" : "Skip"}
      isNextEnabled={true}
      contentWidth="500px"
    >
      <Column sx={{ pt: 2 }}>
        <AgentSigningBonusSlider
          signingBonus={signingBonus}
          setSigningBonus={setSigningBonus}
        />
      </Column>
    </StepLayout>
  )
}

function SetUpPasswordStep({
  password,
  setPassword,
  password2,
  setPassword2,
  passwordError,
  setPasswordErrorFromData,
  onPasswordValidated,
  canEnterSigningBonus,
  isSigningUp,
  signupFinished,
}) {
  const { isValidating: isValidatingPassword, validatePasswords } =
    useValidatePassowrds()
  const isNextEnabled = !!password && !!password2 && !passwordError
  const stepTitle = "Set up your password"
  const handleKeyDownEvent = useHandleStepInputKeyDownEventWrapper(
    isNextEnabled,
    handleNextButtonClicked
  )
  const mixpanelTrackStepFinished = useMixpanelTrackStepFinished()
  const { activeStep } = useWizard()
  const { goToStep, nextStep } = useStepNavigator()

  function handleNextButtonClicked() {
    validatePasswords(password, password2, setPasswordErrorFromData).then(
      () => {
        mixpanelTrackStepFinished({}, stepTitle)
        onPasswordValidated()
      }
    )
  }

  useEffect(
    () => {
      if (signupFinished) {
        nextStep()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [signupFinished]
  )

  return (
    <StepLayout
      title={stepTitle}
      subtitle="This will be the password you use to access your account."
      isNextEnabled={isNextEnabled}
      isLoading={isValidatingPassword || isSigningUp}
      onNextButtonClicked={handleNextButtonClicked}
      onPreviousButtonClicked={() =>
        goToStep(canEnterSigningBonus ? activeStep - 1 : activeStep - 2)
      }
    >
      <PasswordFields
        password={password}
        setPassword={setPassword}
        password2={password2}
        setPassword2={setPassword2}
        passwordError={passwordError}
        handleKeyDownEvent={handleKeyDownEvent}
      />
    </StepLayout>
  )
}

function SendPhoneCodeStep({
  phone,
  setPhone,
  phoneError,
  setPhoneError,
  onPhoneCodeSent,
}) {
  const { isSendingPhoneVerification, sendVerification } =
    useSendPhoneVerificationSMS()
  const [isVerifyPhoneCodeDialogOpen, setIsVerifyPhoneCodeDialogOpen] =
    useState(false)
  const { nextStep } = useStepNavigator()
  const isNextEnabled = !!phone && !phoneError

  const mixpanelStepFinishedData = {
    $phone: phone,
  }
  const stepTitle = "Activate your account"
  const mixpanelTrackStepFinished = useMixpanelTrackStepFinished()
  const klaviyoTrackAnonymousStep = useKlaviyoTrackAnonymousStep("activate")
  const handleKeyDownEvent = useHandleStepInputKeyDownEventWrapper(
    isNextEnabled,
    handleNextButtonClicked
  )
  const isAdminSignup = useIsAdminSignup()

  function handleNextButtonClicked() {
    if (isAdminSignup) {
      onPhoneCodeSent()
    } else {
      sendVerification(phone, setPhoneError).then(() => {
        mixpanelTrackStepFinished(mixpanelStepFinishedData, stepTitle)
        klaviyoTrackAnonymousStep({ phone })
        onPhoneCodeSent()
        setIsVerifyPhoneCodeDialogOpen(true)
      })
    }
  }

  function handlePhoneCodeDialogClosed(phoneVerified) {
    setIsVerifyPhoneCodeDialogOpen(false)
  }

  return (
    <StepLayout
      title={stepTitle}
      subtitle="With account activation via SMS, we only allow verified accounts into our platform."
      isPreviousEnabled={isAdminSignup}
      isNextEnabled={isNextEnabled}
      isLoading={isSendingPhoneVerification}
      onNextButtonClicked={handleNextButtonClicked}
      mixpanelStepFinishedData={mixpanelStepFinishedData}
    >
      <Column>
        <TextField
          label="Phone"
          value={phone}
          onChange={setPhone}
          error={!!phoneError}
          helperText={phoneError}
          onKeyDown={handleKeyDownEvent}
          type="tel"
          fullWidth
        />
        <PhoneAgreementCheckbox />
      </Column>
      <VerifyPhoneCodeDialog
        open={isVerifyPhoneCodeDialogOpen}
        onClose={handlePhoneCodeDialogClosed}
        phone={phone}
        handlePhoneVerified={() => nextStep()}
      />
    </StepLayout>
  )
}

function AdminPhoneConfirmationLinkSentStep() {
  function handleResetWizardButtonClicked() {
    window.location.reload()
  }

  return (
    <StepLayout
      title="Phone confirmation link sent"
      subtitle={`
        They should receive a phone verification link within a minute.
        They can use the link to verify their phone, create a password and log in.`}
      isNextEnabled={false}
      showButtons={false}
    >
      <Button
        variant="contained"
        color="primary"
        startIcon={<ReplayOutlinedIcon />}
        onClick={handleResetWizardButtonClicked}
        fullWidth
      >
        Reset Wizard
      </Button>
    </StepLayout>
  )
}

function AllSetStep({ onLastStepReached }) {
  const isMobile = useIsMobile()
  const gtmTrack = useGTMDispatch()

  useEffect(
    () => {
      gtmTrack({ event: "agent-signup-finished" })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(
    () => onLastStepReached,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <StepLayout
      showButtons={false}
      contentWidth="100%"
      mixpanelStepTitle="You are all set!"
    >
      <Column>
        <Column sx={{ alignItems: "center", textAlign: "center" }}>
          <Typography variant="h4">Now let's explore HighRise!</Typography>
          <Typography
            variant="body1"
            color="text.secondary2"
            sx={{ maxWidth: "25em", mt: 2 }}
          >
            Your profile is now live. Time to start browsing competing offers
            from top local brokerages!
          </Typography>
          <AccessPortalButton />
        </Column>
        <Paper
          elevation={2}
          sx={{
            mt: 4,
            p: 4,
            width: "100%",
            position: "relative",
            "& > img": {
              position: "absolute",
              display: {
                xs: "none",
                sm: "initial",
              },
            },
          }}
        >
          <Row sx={{ "& > *": { flexBasis: { sm: "50%" } } }}>
            <Column
              sx={{
                justifyContent: "center",
                alignItems: { xs: "center", sm: "flex-start" },
              }}
            >
              <img
                src={MailCircleImage}
                style={{ width: "128px", height: "128px" }}
                alt="Mail icon"
              />
              <Typography
                variant="caption"
                sx={{
                  mt: 4,
                  fontSize: "1em",
                  textAlign: { xs: "center", sm: "left" },
                }}
              >
                Log into your HighRise account regularly to respond to brokers
                and keep an eye on your <strong>offer notifications</strong>{" "}
                from us!
              </Typography>
            </Column>
            {!isMobile && (
              <img
                src={ConversationExampleImage}
                style={{ width: "375px", height: "390px", marginLeft: "16px" }}
                alt="Agent and broker conversation"
              />
            )}
          </Row>
          <img
            src={LeftGuyIcon}
            alt="Agent #1"
            style={{ top: -10, left: -110, width: 64, height: 64 }}
          />
          <img
            src={LeftGirlIcon}
            alt="Agent #2"
            style={{ top: "35%", left: -100, width: 64, height: 64 }}
          />
          <img
            src={RemaxLeftIcon}
            alt="Remax"
            style={{ bottom: "10%", left: -130, width: 52, height: 52 }}
          />
          <img
            src={GuyRightIcon}
            alt="Agent #3"
            style={{ top: "15%", right: -75, width: 52, height: 52 }}
          />
          <img
            src={GirlRightIcon}
            alt="Agent #4"
            style={{ top: "50%", right: -110, width: 62, height: 62 }}
          />
          <img
            src={Century21RightIcon}
            alt="Century 21"
            style={{ bottom: "5%", right: -75, width: 44, height: 44 }}
          />
        </Paper>
      </Column>
    </StepLayout>
  )
}

function StepLayout({
  title,
  superTitle,
  subtitle,
  mixpanelStepTitle,
  mixpanelStepFinishedData = {},
  showButtons = true,
  isPreviousEnabled = true,
  isNextEnabled,
  isLoading,
  children,
  rightSection,
  onPreviousButtonClicked,
  nextButtonLabel = "Continue",
  onNextButtonClicked,
  fullWidth = false,
  contentWidth = "400px",
}) {
  const { activeStep, nextStep } = useStepNavigator()
  const mixpanelStepTitle_ = mixpanelStepTitle || title
  useTrackSignupStep(activeStep, mixpanelStepTitle_)
  const mixpanelTrackStepFinished = useMixpanelTrackStepFinished()

  // replacement for handleStep which stopped working after using goToStep instead
  function handleNextButtonClicked() {
    if (!onNextButtonClicked) {
      onNextButtonClicked = nextStep
    }
    mixpanelTrackStepFinished(mixpanelStepFinishedData, mixpanelStepTitle_)
    onNextButtonClicked()
  }

  const stepButtonsRowProps = {
    show: showButtons,
    isLoading,
    isNextEnabled,
    isPreviousEnabled,
    nextButtonLabel,
    onNextButtonClicked: handleNextButtonClicked,
    onPreviousButtonClicked,
  }

  return (
    <>
      <Grid xs={12} sm={9}>
        <Box
          sx={{
            mb: 5,
          }}
        >
          <SignupWizardProgress />
        </Box>
      </Grid>
      {/* add invisible left column to put content on right side */}
      {fullWidth ? null : <Grid xs={12} sm={3} />}
      <Grid xs={12} sm={fullWidth ? 12 : 9}>
        <Column
          sx={{
            alignItems: fullWidth ? "flex-start" : "center",
            width: "100%",
          }}
        >
          <Column
            sx={{
              width: { xs: "100%", sm: fullWidth ? "100%" : contentWidth },
            }}
          >
            {fullWidth ? (
              <AnimatedStepWrapper>
                <Grid container columnSpacing={4} p={0}>
                  <Grid xs={12}>
                    <StepSuperTitle title={superTitle} />
                  </Grid>
                  <Grid xs={12} sm={4}>
                    <Column>
                      <StepTitleSubtitle
                        title={title}
                        titleVariant={superTitle ? "h5" : "h4"}
                        subtitle={subtitle}
                        fullWidth={fullWidth}
                      />
                      <StepContentContainer>{children}</StepContentContainer>
                      <StepNavButtons {...stepButtonsRowProps} />
                    </Column>
                  </Grid>
                  <Grid xs={12} sm={8}>
                    {rightSection}
                  </Grid>
                </Grid>
              </AnimatedStepWrapper>
            ) : (
              <AnimatedStepWrapper>
                <StepSuperTitle title={superTitle} />
                <StepTitleSubtitle
                  title={title}
                  subtitle={subtitle}
                  fullWidth={fullWidth}
                />
                <StepContentContainer>{children}</StepContentContainer>
              </AnimatedStepWrapper>
            )}
            {!fullWidth && <StepNavButtons {...stepButtonsRowProps} />}
          </Column>
          <ConfirmLeaveSignupWizardDialog />
        </Column>
      </Grid>
    </>
  )
}

function StepTitleSubtitle({
  title,
  titleVariant = "h4",
  subtitle,
  fullWidth = false,
}) {
  const textAlign = { xs: "left", sm: fullWidth ? "left" : "center" }
  return (
    <>
      <Typography variant={titleVariant} sx={{ textAlign }}>
        {title}
      </Typography>
      <Typography
        variant="body1"
        sx={{ color: "text.secondary", mt: 1, textAlign }}
      >
        <span dangerouslySetInnerHTML={{ __html: subtitle }} />
      </Typography>
    </>
  )
}

function StepSuperTitle({ title }) {
  return title ? (
    <Typography
      variant="h4"
      alignSelf="center"
      sx={{ textAlign: { xs: "left", sm: "center" }, mb: { xs: 2, sm: 4 } }}
    >
      {title}
    </Typography>
  ) : null
}

function StepNavButtons({
  show,
  onPreviousButtonClicked,
  onNextButtonClicked,
  isPreviousEnabled,
  isNextEnabled,
  isLoading,
  nextButtonLabel,
}) {
  const { previousStep } = useStepNavigator()
  const { activeStep } = useWizard()
  const theme = useTheme()
  const isAdminSignup = useIsAdminSignup()

  const showAccessPortalButton =
    activeStep === STEP_INDEXES.phoneCode && !isAdminSignup

  return show ? (
    <Column>
      <Row
        sx={{
          mt: 4,
          justifyContent: "center",
          [theme.breakpoints.down("md")]: {
            mt: "auto",
            padding: "50px 0px",
          },
        }}
      >
        {activeStep > 0 && (
          <Button
            variant="outlined"
            onClick={onPreviousButtonClicked || previousStep}
            sx={{ flexBasis: "50%" }}
            disabled={!isPreviousEnabled}
          >
            Go back
          </Button>
        )}
        <LoadingButton
          variant="contained"
          onClick={onNextButtonClicked}
          disabled={!isNextEnabled}
          isLoading={isLoading}
          size="large"
          sx={{
            ml: 2,
            flexBasis: "50%",
            [theme.breakpoints.down("md")]: {
              flexBasis: activeStep > 0 ? "50%" : "100%",
            },
          }}
        >
          {nextButtonLabel}
        </LoadingButton>
      </Row>
      {showAccessPortalButton && <AccessPortalButton />}
    </Column>
  ) : null
}

function HomeLocationMapContainer({ mapContainer }) {
  return (
    <Box
      ref={mapContainer}
      sx={{
        width: "100%",
        height: { xs: "320px", sm: "640px" },
        position: "relative",
        borderRadius: (theme) => theme.units.borderRadius,
      }}
    >
      <Row
        sx={{
          alignItems: "center",
          p: 1,
          backgroundColor: "rgba(52.3, 51.4, 54.6, 0.75)",
          borderRadius: (theme) => theme.units.borderRadius,
          position: "absolute",
          bottom: 24,
          left: "calc(50% - 120px)",
          zIndex: 1,
        }}
      >
        <ExploreIcon fontSize="small" sx={{ color: "white" }} />
        <Typography
          variant="body1"
          color="white"
          sx={{ ml: 1, fontSize: "0.75rem" }}
        >
          Move the map to adjust the location
        </Typography>
      </Row>
    </Box>
  )
}

function webhookTrack(payload) {
  axios.post("https://hook.us1.make.com/cp37so2g5l15c2kwu7koqo569idub8zx", {
    environment: process.env.REACT_APP_ENVIRONMENT,
    ...payload,
  })
}

function createGeoJsonFromPinLocation(pinLocation) {
  return {
    type: "Point",
    coordinates: [pinLocation.lng, pinLocation.lat],
  }
}

function useSelectBrokerageChecklistRequirements(
  brokerages,
  onBrokerageSelectedChanged
) {
  const [brokerageSearch, setBrokerageSearch] = useState("")

  const filteredBrokerages = filterBrokeragesByQuery(
    brokerages,
    brokerageSearch
  ).sort((b1, b2) => b1.company.localeCompare(b2.company))

  function handleBrokerageCheckedChangedWrapper(id) {
    return function (e) {
      onBrokerageSelectedChanged(id, e.target.checked)
    }
  }

  return {
    brokerageSearch,
    setBrokerageSearch,
    filteredBrokerages,
    handleBrokerageCheckedChangedWrapper,
  }
}

function filterBrokeragesByQuery(brokerages, brokerageSearch) {
  return brokerages.filter((brokerage) => {
    let searchTarget = brokerage.company
    if (brokerage.brand) {
      searchTarget += ` ${brokerage.brand.name}`
    }
    if (brokerage.address) {
      searchTarget += ` ${brokerage.address}`
    }
    return (
      searchTarget.toLowerCase().indexOf(brokerageSearch.toLowerCase()) > -1
    )
  })
}

function BrokerageSearchTextField({ search, setSearch, mt = 2 }) {
  return (
    <BorderlessSearchTextField
      placeholder="Search for brokerage"
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      InputProps={SearchInputProps}
      sx={{
        mt,
        "& .MuiInputBase-root": {
          pl: 0,
        },
      }}
    />
  )
}

function SelectBrokeragesChecklist({
  filteredBrokerages,
  selectedBrokeragesStatus,
  onBrokerageSelectedChangedWrapper,
  maxHeight = "300px",
}) {
  return (
    <FormGroup
      sx={{
        mt: 2,
        overflowY: "auto",
        maxHeight: maxHeight,
        flexWrap: "nowrap",
      }}
    >
      {filteredBrokerages.map(({ id, company }) => (
        <FormControlLabel
          key={id}
          control={
            <Checkbox
              checked={selectedBrokeragesStatus[id]}
              onChange={onBrokerageSelectedChangedWrapper(id)}
              size="small"
              disableRipple
            />
          }
          label={company}
        />
      ))}
    </FormGroup>
  )
}

function AccessPortalButton() {
  const navigate = useNavigate()
  const isSigningUp = useContext(IsSigningUpContext)
  return (
    <Button
      variant="contained"
      size="large"
      onClick={() => navigate("/agent/home")}
      sx={{ mt: { xs: 0, sm: 4 }, px: 8 }}
      disabled={isSigningUp}
    >
      View local brokerage offers
    </Button>
  )
}

function SignupErrorSnackbar({ open, onClose }) {
  return (
    <Snackbar
      open={open}
      onClose={onClose}
      autoHideDuration={10000}
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
    >
      <Alert
        severity="error"
        onClose={onClose}
        variant="filled"
        sx={{ width: "100%" }}
      >
        Signing up failed. Please try again later.
      </Alert>
    </Snackbar>
  )
}

function useMixpanelTrackStepFinished() {
  const { activeStep } = useStepNavigator()
  const unfinishedData = useContext(UnfinishedDataContext)

  return (mixpanelStepFinishedData, mixpanelStepTitle) => {
    mixpanelUpdateAgent(unfinishedData.email, mixpanelStepFinishedData)
    segmentAnalytics.track("Agent signup step finished", {
      stepIndex: activeStep + 1,
      stepTitle: mixpanelStepTitle,
      ...mixpanelStepFinishedData,
    })
  }
}

function useKlaviyoTrackAnonymousStep(stepName) {
  const { email } = useContext(UnfinishedDataContext)
  return (stepData) => {
    axiosClient.post("/klaviyo-integration/api/track-anonymous-signup/", {
      event_data: {
        ...stepData,
        email,
        last_step: stepName,
      },
    })
  }
}

function useIsAdminSignup() {
  const location = useLocation()
  return location.pathname.split("/")[1] === "admin"
}

const PurpleChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: "white",
  fontSize: "inherit",
  height: "100%",
}))

const IsSigningUpContext = createContext(false)
